/* src/components/Footer/Footer.css */
.footer {
    background-color: #333;
    color: #f4cb7c;
    padding: 0px;
    text-align: right;
    padding-right: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  .footer-link {
    color: #f4cb7c;
    text-decoration: none;
  }
  
  .footer-link:hover {
    color: #e6a73c;
  }
  
.faq-item {
    margin-bottom: 20px;
}

.faq-item p {
    margin: 0;
    line-height: 1.6;
}

.faq-item p strong {
    display: block;
    margin-bottom: 5px;
}

.faq-question {
    font-weight: bold;
    margin-bottom: 5px;
}

.faq-answer {
    margin: 0;
    line-height: 1.6;
}
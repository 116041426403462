/* Import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');



/* Basic styling for the entire application */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/public/background_v4.png'); /* Correct path to the image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.outer-container {
  display: flex;
  justify-content: center;
  padding-top: 60px; /* Spacing from the top */
  padding-bottom: 20px; /* Spacing from the bottom */
  font-size: 22px; /* Font size */
  color: #f4cb7c; /* Text color */
  font-family: 'Montserrat', sans-serif; /* Apply the Montserrat font */
}

.inner-container {
max-width: 1000px;
margin: 0 auto;
padding: 20px;
text-align: left;
}

.inner-container-centered {
max-width: 1000px;
margin: 0 auto;
padding: 20px;
text-align: center;
}

.centered-textblock {
  text-align: center;
}

/* Styling of a background block for e.g. citations */
.background-block {
  background-color: rgba(51, 51, 51, 0.685);
  width: calc(100% - 450px); /* This will be applied for larger screens */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  box-sizing: border-box;
}

/* Responsive design for mobile devices */
@media screen and (max-width: 1200px) {
  .background-block {
    width: 90%; /* Set to 90% of the screen width for smaller devices */
  }
}
  
/* text styling */

.common-text {
  font-family: 'Arial, sans-serif'; /* Example font */
  font-size: 16px;                 /* Example size */
  color: #f4cb7c;                /* Example color */
  line-height: 1.5;                /* Example line height */
  margin: 0;
  padding: 0;
}

.signature {
  font-family: 'Dancing Script', serif;
  font-size: 60px;
}

.my-heading {
  text-align: center;
  color: #f4cb7c; /* Ensure heading color matches */
  }

.my-welcome-heading {
  text-align: center;
  font-size: 80px;
  font-family: 'Dancing Script', serif;
  color: #f4cb7c; /* Ensure heading color matches */
  }
    
.my-heading-dancingscript {
    text-align: center;
    font-size: 70px;
    font-family: 'Dancing Script', serif;
    color: #f4cb7c; /* Ensure heading color matches */
    }
  

/* Link styling */

.text-link {
  color: #ffffff; /* Default text color for links */
  text-decoration: underline; /* Remove underline from links */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition for color and background changes */
}
    
.text-link:hover {
  color: #f4cb7c; /* Text color on hover */
  background-color: transparent; /* Transparent background on hover */
  text-decoration: underline; /* Add underline on hover */
}
    
.text-link:active {
  color: #e6a73c; /* Text color when active (clicked) */
}

/* Special styling for the "Dominik & Aline" link */
.home-link {
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
  padding-bottom: 4px;
}

/* Dropdown menu styling */
.dropdown {
  position:inherit;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  background-color: rgb(51, 51, 51, 0.97);
  padding: 0;
  margin-top: 14px; /* Aligns with Navbar li padding */
  z-index: 1;
  width: 160%;
  /* border-bottom-left-radius: 5px;
  border-left-color: #f4cb7c;
  border-left-width: 3px;
  border-left-style: solid;
  border-bottom: #f4cb7c;
  border-bottom-width: 3px;
  border-bottom-style: solid; */
}

.dropdown-menu li {
  padding: 10px 20px; /* Adjust padding to fit the clickable area */
  white-space: nowrap;
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  display: block;
  width: fit-content;
  background: rgba(51, 51, 51, 0);
}

.dropdown-menu a:hover {
  color: #f4cb7c;
  background-color: transparent;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Adjust dropdown menu width */
.dropdown-menu a {
  width: calc(100% + 20px); /* 20px wider than the text */
  margin-left: -10px; /* Align center */
  margin-right: -10px; /* Align center */
}

.Navbar a:hover {
  background-color: transparent;
}

/* Ensure the image is responsive */
.responsive-image {
  max-width: 100%;      /* Image will scale down to fit within its container */
  height: auto;         /* Maintain the aspect ratio of the image */
  border-radius: 10px;  /* Optional: Adds rounded corners to the image */
  }
  
  /* Example container styles */
  .image-container {
  display: flex;
  justify-content: center;  /* Center the image within the container */
  margin: 20px 0;           /* Optional: Add space around the container */
  }
  
  /* Optional: Limit the maximum width of the image for readability */
  @media screen and (min-width: 768px) {
  .responsive-image {
    max-width: 570px;  /* Example maximum width */
  }
}

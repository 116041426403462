.Navbar {
  background-color: rgba(51, 51, 51, 0.97);
  overflow: visible;
  height: 55px;
  font-size: 20px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the navbar is above other content */
}

.Navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  z-index: 1001; /* Ensure it is above the menu */
}

.Navbar .home-link {
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
  padding-bottom: 4px;
  color: white;
  text-decoration: none;
  z-index: 1002; /* Ensure it's above the burger menu */
  margin-right: 20px; /* Added margin to provide space between the home link and the first nav item */
}

.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1002; /* Ensure it's clickable */
}

.burger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Ensure it's under the burger menu */
}

.nav-links li {
  padding: 14px 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  position: relative;
}

.nav-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: white;
  transition: width 0.2s ease, background-color 0.2s ease;
}

.nav-links a:hover::after {
  width: 100%;
  left: 0;
  background: #f4cb7c;
}

.nav-links a:hover {
  color: #f4cb7c;
  background-color: transparent;
}

/* Special styling for the "Dominik & Aline" link */
.home-link {
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
  padding-bottom: 4px;
}

/* Dropdown menu styling */
.dropdown {
  position: inherit;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  background-color: rgb(51, 51, 51, 0.97);
  padding: 0;
  margin-top: 0px; /* Aligns with Navbar li padding */
  z-index: 1;
  width: 200px;
  text-align: left;
  list-style-type: none; /* Remove bullets from the dropdown menu */
}

.dropdown-menu li {
  padding: 10px 10px; /* Adjust padding to fit the clickable area */
  white-space: nowrap;
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  display: block;
  padding-left: 5px; /* Keep padding consistent */
  margin-left: 0; /* Remove margin adjustment */
  background: rgba(51, 51, 51, 0);
}

.dropdown-menu a:hover {
  color: #f4cb7c;
  background-color: transparent;
  margin-left: 0; /* Ensure margin remains consistent on hover */
  width: 100%; /* Adjust width to ensure no shift */
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.Navbar a:hover {
  background-color: transparent;
}

@media screen and (max-width: 1230px) {
  .Navbar-container {
    justify-content: space-between;
  }

  .nav-links {
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%;
      height: calc(100vh - 65px);
      flex-direction: column;
      background-color: rgba(51, 51, 51, 0.97);
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      opacity: 0;
      z-index: 1000; /* Ensure the menu is above other content */
  }

  .nav-links.open {
      transform: translateY(0);
      opacity: 1;
  }

  .burger-menu {
      display: flex;
      z-index: 1002; /* Ensure it's clickable */
  }

  .nav-links li {
      width: 100%;
      text-align: center;
      padding: 20px 0;
  }

  .nav-links a {
      width: 100%;
  }

  .Navbar .home-link {
      margin-left: 0;
      z-index: 1002; /* Ensure it's clickable */
  }

  /* Hover effect for mobile (when burger menu is open) */
  .nav-links.open a:hover {
      background-color: transparent;
      color: #f4cb7c;
  }

  .nav-links.open a:hover::after {
      width: 100%;
      left: 0;
      background: #f4cb7c;
  }
}
